.form {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    padding: 24px 1rem;
}

.divider {
    height: 25px;
    width: 2px;
    background-color: var(--grey-400);
    margin: 0 0.5rem;
}

.checkboxInput {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 1rem;
    gap: var(--gap-half);
    white-space: nowrap;
    color: var(--grey-600);
    font-weight: 600;
    cursor: pointer;
}

.checkboxInput:hover {
    color: var(--primary-500);
}

.checkboxInput svg {
    color: var(--grey-600);
}

.checkboxInput.selected svg {
    color: var(--primary-500);
}

.checkboxInput.disabled {
    color: var(--secondary-darkest);
    cursor: default;
}

.checkboxInput.disabled svg {
    color: var(--secondary-darkest);
}

.autoSendSelect {
    background-color: white;
    border: 1px solid var(--primary-500);
    border-radius: 4px;
    font-size: 13px;
    font-weight: 600;
    height: 22px;
    line-height: 22px;
}

.whitelistitem {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 16px 24px;
}

.whitelistitem:nth-child(odd) {
    background-color: white;
}

.whitelistitem:last-child {
    border-bottom: none;
}

.item {
    flex: 1;
    /* Prevent shrinking */
    /* min-width: 0; */
    display: flex;
    flex-direction: column;
    gap: 8px;
    overflow: hidden;
}

.item label {
    color: var(--grey-500);
    font-weight: 600;
    font-size: 14px;
}

.item span {
    display: block;
    color: var(--grey-800);
    font-weight: 600;
    font-size: 16px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.defaultVehicleClassSelect {
    width: 80%;
}

h3.sectionTitle {
    margin: 0;
    text-decoration: underline;
}

.radioItem {
    display: flex;
    gap: 4px;
    align-items: center;
    margin: 4px 0 8px 0;
}

.radioItem input {
    accent-color: var(--primary-500);
}

.radioItem label {
    color: var(--secondary-black);
    font-size: var(--text-sm);
    font-weight: 600;
}
