.wrapper {
    display: flex;
    flex-direction: column;
    width: 100%;
}

.wrapper.narrow {
    width: fit-content;
}

.input {
    position: relative;
    display: flex;
    align-items: center;
    border: 2px solid var(--grey-600);
    border-radius: var(--radius);
    background: var(--everest-white);
    width: 100%;
}


.input.narrow {
    width: fit-content;
    margin-right: .875rem;
}

.input input {
    border: 0;
    border-radius: var(--radius);
    outline: none;
    line-height: 1;
    padding: 0.6rem 1rem;
    width: 100%;
}

.medium input {
    padding: 0.625rem;
    padding-left: 0.875rem;
    padding-right: 1rem;
    font-size: var(--text-base);
}

.small input {
    padding: 0.375rem;
    padding-left: 0.75rem;
    padding-right: 2.5rem;
    font-size: var(--text-sm);
}

.hasIcons input {
    padding-left: 0rem;
}

.inverseColors.input {
    border: 2px solid var(--secondary-darker);
    border-radius: var(--radius);
    background: var(--secondary);
}

.inverseColors.input input {
    background: var(--secondary);
}

.input:hover,
.input:focus-within {
    border-color: var(--primary-500);
}

.input label {
    position: absolute;
    top: 0.625rem;
    left: 0.75rem;
    padding: 0 0.25rem;
    width: auto;
    white-space: nowrap;
    transition: var(--transition);
    color: var(--secondary-darkest);
    font-weight: 600;
    cursor: text;
    background: linear-gradient(0deg, var(--everest-white) 52%, transparent 48%);
}

.inverseColors.input label {
    background: linear-gradient(0deg, var(--secondary) 52%, transparent 48%);
}

.hasIcons label {
    left: 2.5rem;
}

.medium label {
    top: 0.75rem;
    left: 0.5rem;
    font-size: var(--text-base);
}

.small label {
    top: 0.375rem;
    left: 0.5rem;
    font-size: var(--text-base);
}

.hasIcons.medium label {
    left: 2.25rem;
}

.hasIcons.small label {
    left: 2rem;
}

.input:focus-within label {
    color: var(--primary-500);
}

.input:focus-within label,
.input .active,
.small:focus-within label,
.small .active {
    top: -0.625rem;
    font-size: var(--text-sm);
}

.small:focus-within label,
.small .active {
    font-size: var(--text-xs);
}

.icon {
    z-index: 50;
    display: flex;
    justify-content: center;
    align-items: center;
    min-width: 2.75rem;
    font-size: var(--text-3xl);
    color: var(--secondary-darkest);
}

.medium .icon {
    min-width: 2.5rem;
    font-size: var(--text-xl);
}

.small .icon {
    min-width: 2.25rem;
    font-size: var(--text-lg);
}

.errMessage {
    padding-top: 0.25rem;
}

.horizontalInputWrapper .errInput {
    border-color: var(--everest-red);
}

.horizontalWrapper {
    display: flex;
    flex-direction: column;
    gap: var(--gap-quarter);
}

.horizontalInputWrapper {
    display: flex;
    align-items: center;
}

.horizontalInputWrapper .label {
    display: flex;
    flex-direction: column;
    font-size: var(--text-base);
    font-weight: 600;
    width: 100%;
    max-width: 10rem;
}

.horizontalInputWrapper .label .subtitle {
    font-size: var(--text-sm);
    font-weight: 500;
    font-style: italic;
}

.horizontalInput {
    width: 100%;
    height: 2.5rem;
    padding: 0.5rem;
    border: 2px solid var(--grey-600);
    border-radius: var(--radius-sm);
    outline: none;
    font-size: var(--text-base);
}

.horizontalInput:focus-within {
    border-color: var(--primary-500);
}

.horizontalErrMessage {
    margin-left: 10rem;
    font-size: var(--text-sm);
}

.changed {
    font-weight: 600;
}

.textBottomMargin {
    margin-bottom: .875rem;
}

.dateTimePickerInput {
    position: relative;
    display: flex;
    flex-direction: column;
    margin: 0;
    outline: none;
    border: 2px solid var(--grey-600);
    border-radius: var(--radius-sm);
    background-color: var(--everest-white);
    width: 100%;
}

.dateTimePickerInput input {
    outline: none;
    border-style: none;
    color: var(--grey-700);
}

.dateTimePickerInput:focus-within {
    border-color: var(--primary-500);
}

.dateTimePickerInput label {
    display: block !important;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    position: absolute;
    top: -0.75rem;
    left: 0.5rem;
    max-width: calc(100% - 1.25rem);
    display: flex;
    align-items: center;
    gap: var(--gap-quarter);
    padding: 0 0.25rem;
    background: linear-gradient(0deg, var(--everest-white) 52%, transparent 48%);
    font-weight: 600;
    font-size: 14px;
}

.dateTimePickerInput label:hover {
    color: var(--primary-500);
}

.formStyling.input {
    border: none;
}

.multiEmailInputTagInput {
  flex-grow: 1;
}

.multiEmailInputTagInputField {
  border: none;
  background-color: transparent;
  width: 100%;
}

.multiEmailInputSelected {
  display: flex;
  flex-wrap: wrap;
  gap: 4px;
  border: 2px solid var(--grey-600);
  border-radius: var(--radius-sm);
  padding: 4px 8px;
}

.multiEmailInputTag {
  border: 1px solid #ddd;
  background: var(--primary-500);
  color: #fff;
  font-size: 14px;
  padding: 2px 4px;
  border-radius: 2px;
  display: flex;
  align-items: center;
  gap: 2px;
}

.multiEmailInputRemove {
  border: none;
  cursor: pointer;
  background: none;
  color: #fff;
}

.multiEmailInputRemove > svg {
  width: 8px;
  height: 8px;
}